"use strict"

export class Poligono {
  #data
  #coords
  constructor({ data, coords }) {
    this.#data = data
    this.#coords = coords
    this.options = this.options.bind(this)
    this.info = this.info.bind(this)
  }
  get data() { return this.#data }
  get coords() { return this.#coords }
  get events() { return [] }
  options() { return {} }
  info() {}
  // infoRight,
  // eventsRight
}